import React from 'react'
import Img from 'gatsby-image'

import { Link, graphql } from 'gatsby'
import Layout from 'src/components/layout'
import Row from 'src/components/row'
import { Helmet } from 'react-helmet'

const Page = ({
  data: {
    site,
    imgKingsway1,
    imgKingsway2,
    imgSunnylea1,
    imgSunnylea2,
    imgBloorW,
    imgCommercial,
    imgPlumbing,
  },
}) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>Photo Gallery - {site.siteMetadata.title}</title>
      </Helmet>
      <h1>Gallery</h1>
      {/* <p>Click on a picture to view images from that project</p> */}
      <hr />
      <h2>Residential Projects</h2>
      <br />
      <Row columns="3">
        <Link to="/the-kingsway-residence-1">
          <p>
            <u>Kingsway Residence 1</u>
          </p>
          <Img fluid={imgKingsway1.childImageSharp.fluid} />
        </Link>
        <Link to="/the-kingsway-residence-2">
          <p>
            <u>Kingsway Residence 2</u>
          </p>
          <Img fluid={imgKingsway2.childImageSharp.fluid} />
        </Link>
        <Link to="/bloor-west-village">
          <p>
            <u>Bloor West Village</u>
          </p>
          <Img fluid={imgBloorW.childImageSharp.fluid} />
        </Link>
      </Row>
      <Row columns="3">
        <Link to="/sunnylea-1">
          <p>
            <u>Sunnylea Residence 1</u>
          </p>
          <Img fluid={imgSunnylea1.childImageSharp.fluid} />
        </Link>
        <Link to="/sunnylea-2">
          <p>
            <u>Sunnylea Residence 2</u>
          </p>
          <Img fluid={imgSunnylea2.childImageSharp.fluid} />
        </Link>
        <div />
      </Row>
      <hr />
      <h2>Commercial Projects</h2>
      <Row columns="3">
        <Link to="/commercial-projects">
          <p>
            <u>Various Commercial Projects</u>
          </p>
          <Img fluid={imgCommercial.childImageSharp.fluid} />
        </Link>
      </Row>
      <hr />
      <h2>Plumbing and Backflow Projects</h2>
      <Row columns="3">
        <Link to="/plumbing-backflow">
          <p>
            <u>Various</u>
          </p>
          <Img fluid={imgPlumbing.childImageSharp.fluid} />
        </Link>
      </Row>
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  query GalleryQuery {
    site {
      siteMetadata {
        title
      }
    }
    imgKingsway1: file(
      relativePath: { regex: "/gallery_overview/the-kingsway-residence-1.png/" }
    ) {
      ...Thumb
    }
    imgKingsway2: file(
      relativePath: { regex: "/gallery_overview/the-kingsway-residence-2.jpg/" }
    ) {
      ...Thumb
    }
    imgSunnylea1: file(
      relativePath: {
        regex: "/gallery_overview/Sunnylea-projectfeature-image.jpg/"
      }
    ) {
      ...Thumb
    }
    imgSunnylea2: file(
      relativePath: {
        regex: "/gallery_overview/Sunnylea2-projectfeature-image.jpg/"
      }
    ) {
      ...Thumb
    }
    imgBloorW: file(
      relativePath: {
        regex: "/gallery_overview/BW-SwanseaR-projectfeature-image.png/"
      }
    ) {
      ...Thumb
    }
    imgCommercial: file(
      relativePath: { regex: "/gallery_overview/commercial.jpg/" }
    ) {
      ...Thumb
    }
    imgPlumbing: file(
      relativePath: { regex: "/gallery_overview/backflow.jpg/" }
    ) {
      ...Thumb
    }
  }
`
